import { ToolResult } from '@/interfaces/toolResult';
import { yesNo, getCurrentDate, toReadableDateFormat } from '@/utilities/filters';

const getYearEndBonusEuros = (toolResult: ToolResult) => {
  if (toolResult.cao.year_end_bonus_type === 'percentage') {
    let result = toolResult.salary;
    if (toolResult.adv === true) {
      result += getAdvEuros(toolResult);
    }
    return parseFloat(
      toolResult.cao.year_end_bonus_amount) * 0.01 * result;
  } else {
    return 0;
  }
};

const getAdvEuros = (result: ToolResult) => {
  return result.adv_perc * 0.01 * result.salary;
};

const getCaoTemplate = (
  result: ToolResult,
  imageSrc: string,
  employeeName: string,
  employerName: string,
  companyName: string) => {
  const advEuros = getAdvEuros(result);
  let grossHourlyWage = result.salary;
  if (result.adv === true) {
    grossHourlyWage += advEuros;
  }
  if (result.showYearEndBonus === true) {
    grossHourlyWage += getYearEndBonusEuros(result);
  }

  return `
  <html>
  <head>
    <style>
      body {
        font-size: 19px;
        font-family: 'Open sans', sans-serif;
      }
      .caotext strong {
        display: block;
        border-bottom: solid 1px black;
      }
      th {
        text-align: left;
        border-bottom: solid 1px black;
      }
      .blueHeader {
        font-size: 25px;
        color: #1FA1E6;
        font-weight: bold;
        margin-bottom: 20px;
        width: 100%;
      }
      .page-break {
        page-break-before: always;
      }
      table, tr, td, th, tbody, thead, tfoot {
        page-break-inside: avoid !important;
      }
      table {
        width: 100%;
        border-color: white;
        border-style: none !important;
        border-spacing: 0px !important;
        border-collapse: collapse !important;
      }
      .company-logo {
        width: 100%;
        height: 80px;
      }
      .company-logo img {
        height: auto;
        max-width: 240px;
        max-height: 240px;
        float: right;
      }
      table td, table th {
        padding: 8px !important;
      }
      table th {
        padding-bottom: 4px !important;
      }
      .caotext table td {
        border: 1px solid black;
      }
      .caotext table tr:first-child td {
        border-top: 0;
      }
      .caotext table tr td:first-child {
        border-left: 0;
      }
      .caotext table tr:last-child td {
      border-bottom: 0;
      }
      .caotext table tr td:last-child {
        border-right: 0;
      }
    </style>
  </head>
  <body>
    <div class="company-logo">
    <img src="${ imageSrc }" />
    </div>
    <br />
    <table>
      <tr><th colspan="2">Gegevens</th></tr>
      <tr>
        <td width="30%">Naam werknemer</td>
        <td>${ employeeName }</td>
      </tr>
      <tr>
        <td>Naam opdrachtgever</td>
        <td>${ employerName }</td>
      </tr>
      <tr>
        <td>Naam intermediair</td>
        <td>${ companyName }</td>
      </tr>
    </table>

    <table>
      <tr><th colspan="2">Cao</th></tr>
      <tr>
        <td width="30%">Cao</td>
        <td>${ result.cao.readable_name }</td>
      </tr>
      <tr>
        <td>Looptijd</td>
        <td>
          ${ toReadableDateFormat(result.cao.duration_start) } - ${ toReadableDateFormat(result.cao.duration_end) }
        </td>
      </tr>
      <tr>
        <td>Aangemeld bij Ministerie:</td>
        <td>${ result.cao?.cao_entry_date === null ? 'Nee' : toReadableDateFormat(result.cao.cao_entry_date!) }</td>
      </tr>
      <tr>
        <td>AVV</td>
        <td>${ yesNo(result.cao.avv) }</td>
      </tr>
      <tr>
        <td>Werkweek</td>
        <td>${ result.workweek }  uur</td>
      </tr>
      <tr>
        <td>Laatste update</td>
        <td>
          ${ toReadableDateFormat(result.salaryTable.active_per) } ${ result.salaryTable.active_per_description}
            (minimumlonen per ${ toReadableDateFormat(result.cao.minimumwage_date) } toegepast)'
        </td>
      </tr>
      <tr>
        <td>Salaristabel</td>
        <td>${ result.salaryTable.name }</td>
      </tr>
      <tr>
        <td>Functie</td>
        <td>${ result.job.name } </td>
      </tr>
      <tr>
        <td>Functieschaal</td>
        <td>${ result.payscale }</td>
      </tr>
      <tr>
        <td>Periodiek</td>
        <td>${ result.periodical !== null ? result.periodical : 'Geen' }</td>
      </tr>
      <tr>
        <td>Geboortedatum</td>
        <td>${ result.birthdate }</td>
      </tr>
      <tr>
        <td>ABU/NBBU berekeningswijze</td>
        <td> ${ yesNo(result.abuNbbu) }</td>
      </tr>
      <tr>
        <td>Datum</td>
        <td>${ getCurrentDate() }</td>
      </tr>
    </table>
    <table>
    <tr><th colspan="2">Loon</th></tr>
    <tr>
      <td width="30%">Basisloon</td>
      <td>&euro;${ result.salary.toFixed(2) }</td>
    </tr>
    <tr><td>Adv <span class="adv-perc">${ result.adv_perc }%</span></td>
    ${
      result.adv === true ? '' +
      `<td>&euro;${ advEuros.toFixed(2) }</td>` : ''
    }
    </tr>
    ${
      result.showYearEndBonus === true ? `
      <tr>
        <td><span class="adv-perc">
          Eindejaarsuitkering ${ result.cao.year_end_bonus_amount }%
        </span>
          <td> &euro; ${(getYearEndBonusEuros(result)).toFixed(2)}</td>
        </td>
      </tr>
      ` : ''
    }
    <tr>
      <td>Bruto uurloon</td>
      <td>&euro;${ grossHourlyWage.toFixed(2) }</td>
    </tr>
  </table>
  <div class="blueHeader page-break">Text uit cao</div>
  <div class="caotext">
  `  +
    result.cao.website_text
    .replace(/<table /g, '<div class="table-responsive"><table class="table" ' )
    .replace(/<\/table>/g, '</table></div>').replace(/tableborder="0"/g, 'tableborder="1"')
    .replace(/border="0"/g, 'border="1"')
    .replace(/px/g, '') + `</div></body></html>`;
};

export default getCaoTemplate;
