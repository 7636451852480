


















import { Component, Prop, Vue } from 'vue-property-decorator';
import { ToolResult } from '@/interfaces/toolResult';
import { Action } from 'vuex-class';
import * as types from '@/store/main/types';
import * as accountTypes from '@/store/account/types';
import VButton from '@/components/VButton.vue';
import getCaoTemplate from '@/pdf-templates/cao';
import { getCurrentDate } from '@/utilities/filters';

@Component({
  components: { VButton },
})
export default class PdfModal extends Vue {
  @Prop({ required: true }) private modalId!: string;
  @Prop({ required: true }) private result!: ToolResult;

  @Action('getCaoPdf', { namespace: 'main' }) private getCaoPdf!: types.GetCaoPdfAction;
  @Action('profileImage', { namespace: 'account' }) private getProfileImage!: accountTypes.ProfileImageAction;

  private employeeName = '';
  private employerName = '';
  private companyName = '';
  private makingPdf = false;

  private imageSrc = '';

  private created() {
    this.getProfileImage().then((response) => {
      this.imageSrc = response;
    });
  }

  private hideModal() {
    (this.$refs[this.modalId] as any).hide();
  }

  private makePdf() {
    this.makingPdf = true;
    const html =
      getCaoTemplate(this.result, this.imageSrc, this.employeeName, this.employerName, this.companyName);
    this.getCaoPdf({ html, filename:
      `${ this.employeeName}_` +
      `${ this.employerName }_` +
      `${ this.result.cao.readable_name }_` +
      `${ getCurrentDate() }`,
    })
    .then((link) => {
      this.hideModal();
      link.click();
    }).finally(() => {
      this.makingPdf = false;
    });
  }
}
