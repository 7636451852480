










































































































































































import { Component, Vue } from 'vue-property-decorator';
import CaoSelector from '@/components/tool/CaoSelector.vue';
import PdfModal from '@/components/modals/pdf-modal/PdfModal.vue';
import AcceptTermsModal from '@/components/modals/AcceptTermsModal.vue';
import { ToolResult } from '@/interfaces/toolResult';
import VButton from '@/components/VButton.vue';
import { Getter, Mutation, Action } from 'vuex-class';
import * as accountTypes from '@/store/account/types';
import { toReadableDateFormat, yesNo, wagesPer } from '@/utilities/filters.ts';


@Component({
  components: { CaoSelector, VButton, AcceptTermsModal, PdfModal },
  filters: { yesNo, toReadableDateFormat, wagesPer },
  name: 'Tool',
})
export default class Tool extends Vue {

  @Getter('lastToolResult', { namespace: 'main' }) private lastToolResult!: ToolResult;
  @Getter('isLoggedIn', { namespace: 'account' }) private isLoggedIn!: boolean;
  @Getter('pdfAllowed', { namespace: 'account' }) private pdfAllowed!: string;
  @Getter('termsAccepted', { namespace: 'main' }) private termsAccepted!: string;
  @Mutation('SET_LAST_TOOLRESULT', { namespace: 'main' }) private setLastToolResult!: (result: ToolResult) => void;

  private hasResult = false;
  private showTerms = false;
  private result: ToolResult = new ToolResult();

  private created() {
    this.result = this.lastToolResult;
    this.hasResult = this.lastToolResult.cao.cao_id !== '';
    if (!this.isLoggedIn) {
      this.showTerms = true;
    }
  }

  private getResults(result: ToolResult) {
    this.result = result;
    this.setLastToolResult(result);
    this.hasResult = true;
  }

  get advEuros() {
    if (this.hasResult) {
      return this.result.adv_perc * 0.01 * this.result.salary;
    } else {
      return 0;
    }
  }

  get yearEndBonusEuros() {
    if (this.hasResult && this.result.cao.year_end_bonus_type === 'percentage') {
      let result = this.result.salary;
      if (this.result.adv === true) {
        result += this.advEuros;
      }
      return parseFloat(
        this.result.cao.year_end_bonus_amount) * 0.01 * result;
    } else {
      return 0;
    }
  }

  get brutoHourlySalary() {
    if (!this.hasResult) {
      return 0;
    }
    let salary = this.result.salary;
    if (this.result.adv === true) {
      salary += this.advEuros;
    }
    if (this.result.showYearEndBonus === true) {
      salary += this.yearEndBonusEuros;
    }
    return salary.toFixed(2);
  }

  get caoWebsiteText() {
    return this.hasResult ? this.result.cao.website_text : '';
  }

  get minimalSalary() {
    if (this.hasResult) {
      return (
        this.result.adv === true ?
        ((this.result.salary * (this.result.adv_perc + 100)) / 100).toFixed(2) :
        this.result.salary);
    } else {
      return null;
    }
  }
}
